@font-face {
  font-family: "ShadowsIntoLight";
  src: url("../assets/fonts/ShadowsIntoLight-Regular.ttf");
}
/* Main Styling */
* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

body {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
  font-size: 100%;
  background-color: #FAF4EF;
}

.block-side-margin {
  margin: 0 50px;
}

@media only screen and (max-width: 499px) {
  .block-side-margin {
    margin: 0 30px;
  }
}
.fp-color-block {
  width: 100%;
}

.block-beta {
  background-color: #1D265E;
}

.block-beta-darker {
  background-color: #171e4b;
}

.block-gamma {
  background-color: #409FDD;
}

.block-white {
  background-color: #FFFFFF;
}

.block-black {
  background-color: #1F1F1F;
}

.content-frame {
  max-width: 1344px;
  margin: 0 auto;
}

p {
  font-size: 1.6rem;
}

a {
  font-size: 1.6rem;
  font-weight: 700;
  text-decoration: none;
  color: #409FDD;
  transition: color 0.7s ease-in-out;
}
a:hover {
  color: #FF5E5B;
}

.logo-text {
  font-family: "ShadowsIntoLight", cursive;
  font-weight: 700;
  letter-spacing: 1.5px;
  color: #1D265E;
}

.txt-lrg {
  color: #1D265E;
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.2;
}

.txt-sub-description {
  margin-top: 3rem;
  font-size: 2rem;
  font-weight: 300;
  letter-spacing: 1px;
}

h1, h2, h3, h4 {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
  font-weight: 700;
}
h1 {
  font-size: 5rem;
  letter-spacing: 1.2px;
  color: #1D265E;
}
h2 {
  font-size: 3.5rem;
  color: #1D265E;
}
h3 {
  font-size: 3rem;
  letter-spacing: 1.2px;
  color: #1D265E;
}
h4 {
  font-size: 2rem;
  letter-spacing: 0.6px;
}

#navbar {
  max-width: 1740px;
  margin: 3rem auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#navbar .logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#navbar .logo-text {
  font-size: 5rem;
  color: #1D265E;
}

.hamburger-icon {
  display: none;
}

.hamburger-icon {
  border: 14px solid transparent;
  background-color: transparent;
  vertical-align: middle;
}

.hamburger-icon .burger-icon,
.hamburger-icon .bones-icon {
  color: #1D265E;
  font-size: 4rem;
  cursor: pointer;
}

.hamburger-icon input[type=checkbox] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center;
}

.toggle-header,
.toggle-link,
.toggle-icon {
  display: none;
}

.nav-item-text .nav-item-btn {
  display: inline-block;
  justify-content: end;
}

.flip-horizontal {
  transform: scaleX(-1);
}

.flip-vertical {
  transform: scaleY(-1);
}

.mobile-socials {
  display: none;
}

.nav-item-btn {
  margin-left: 15px;
}

.nav-item-header span {
  padding: 0 25px;
}
.nav-item-header span .nav-icon {
  margin-right: 2rem;
  margin-bottom: 0.5rem;
}

.nav-item-text a {
  padding: 0 25px;
  font-size: 2rem;
  color: #1D265E;
  transition: color 0.7s ease-in-out;
}
.nav-item-text a:hover {
  color: #FF5E5B;
}
.nav-item-text a .nav-icon {
  margin-right: 2rem;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 999px) {
  #navbar {
    display: block;
  }
  #navbar .logo-text {
    font-size: 4rem;
  }
  .toggle-link,
  .toggle-header {
    display: contents;
  }
  .nav-links {
    height: 0;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
    background-color: #409FDD;
  }
  .nav-links li {
    display: inline-block;
    width: 100%;
    height: 100%;
  }
  .nav-links li a,
  .nav-links li span {
    margin-left: 2rem;
    font-size: 3rem;
    font-weight: 700;
  }
  .nav-links li a .toggle-icon,
  .nav-links li span .toggle-icon {
    display: inline;
  }
  .nav-links li a:hover,
  .nav-links li span:hover {
    color: #FFFFFF;
  }
  .nav-links li:first-child {
    color: #FFFFFF;
    padding-top: 7rem;
    padding-bottom: 2rem;
  }
  .nav-links li:last-child {
    border-bottom: none;
  }
  .nav-links .nav-item-btn {
    display: none;
  }
  .mobile-socials {
    display: contents;
    color: #1F1F1F;
  }
  .mobile-socials a {
    color: #1F1F1F;
  }
  .hamburger-icon {
    display: block;
  }
  .hamburger-icon:hover {
    border: 14px solid transparent;
  }
  .hamburger-icon:active {
    background-color: #409FDD;
    border: 14px solid #409FDD;
  }
  .hamburger-icon .burger-icon:hover, .hamburger-icon .bones-icon:hover {
    color: #111637;
    transition: 0.5s;
  }
  .bones-icon {
    display: none;
  }
  nav:has(.hamburger-icon input[type=checkbox]:checked) .hamburger-icon {
    background-color: #409FDD;
    border: 14px solid #409FDD;
  }
  nav:has(.hamburger-icon input[type=checkbox]:checked) .nav-links {
    height: calc(100vh - 140px);
    margin: 0;
    padding: 0;
  }
  nav:has(.hamburger-icon input[type=checkbox]:checked) .nav-links ul li:nth-child(1) {
    margin-top: 2rem;
  }
  nav:has(.hamburger-icon input[type=checkbox]:checked) .burger-icon {
    display: none;
  }
  nav:has(.hamburger-icon input[type=checkbox]:checked) .bones-icon {
    display: inline-block;
  }
}
#hero-section {
  margin-top: 11rem;
  margin-bottom: 12rem;
  display: flex;
  position: relative;
}

#hero-bg-gradient {
  width: 100%;
  height: 100%;
  border-radius: 15rem;
  background: radial-gradient(105.68% 45.69% at 92.95% 50%, rgba(105, 244, 253, 0.5) 0%, rgba(160, 255, 244, 0.095) 53.91%, rgba(254, 216, 255, 0) 100%), radial-gradient(103.18% 103.18% at 90.11% 102.39%, #C9FFF2 0%, rgba(230, 255, 250, 0) 100%), radial-gradient(90.45% 90.45% at 87.84% 9.55%, #FFD2F5 0%, rgba(254, 219, 246, 0) 100%), linear-gradient(135.66deg, rgba(184, 146, 255, 0.7) 14.89%, rgba(216, 202, 254, 0) 74.33%);
  background-blend-mode: normal, normal, normal, normal, normal, normal;
  filter: blur(125px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

#left-hero-section,
#right-hero-section {
  z-index: 1;
}

#left-hero-section {
  width: 60%;
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
#left-hero-section #hero-text {
  font-size: clamp(3rem, 5vw, 7rem);
  font-weight: 700;
  line-height: 1.1;
  color: #1D265E;
  text-align: left;
}

.hero-tagline-text {
  padding-right: 5rem;
}
.hero-tagline-text p {
  font-size: clamp(2rem, 2vw, 3rem);
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 1.2;
  color: #662E9B;
  margin: 1.5rem 0 2.5rem;
}

#right-hero-section {
  width: 40%;
  height: 100%;
  position: relative;
  text-align: center;
}
#right-hero-section img {
  width: 75%;
  height: auto;
  border: 5px solid #1D265E;
  outline: 5px solid #FAF4EF;
  outline-offset: -10px;
  border-radius: 50%;
}

@media only screen and (max-width: 599px) {
  #hero-bg-gradient {
    display: none;
  }
  #hero-section {
    margin-top: 2rem;
    margin-bottom: 6rem;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-items: center;
  }
  #right-hero-section {
    width: 100%;
  }
  #left-hero-section {
    width: 100%;
  }
  #left-hero-section #hero-text {
    font-size: 5rem;
    text-align: center;
    margin-top: 2rem;
  }
  .hero-tagline-text {
    padding-right: 0rem;
    text-align: center;
  }
  .hero-tagline-text p {
    font-size: 2.5rem;
    margin-bottom: 3rem;
  }
}
#closer-look {
  padding: 100px 0;
}
#closer-look h1 {
  color: #409FDD;
}
#closer-look .txt-sub-description {
  color: #FFFFFF;
}
#closer-look .info-card-container {
  display: grid;
  grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
  gap: 4rem;
  margin-top: 50px;
}
#closer-look .info-card-container .card-light-one {
  background-color: #FAF4EF;
  box-shadow: inset rgba(255, 255, 255, 0.075) 0 0 0 1px;
}
#closer-look .info-card-container .card-light-two {
  background-color: #FAF4EF;
  box-shadow: inset rgba(255, 255, 255, 0.075) 0 0 0 1px;
}
@media only screen and (max-width: 999px) {
  #closer-look .info-card-container {
    grid-template-columns: 100%;
  }
}

#featured-project-header {
  padding-top: 10rem;
}
#featured-project-header h1 {
  color: #1D265E;
}

#featured-project-frame {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 6rem;
  padding-bottom: 10rem;
}
#featured-project-frame .custom-btn {
  margin-top: 4rem;
}

.project-site {
  display: inline-flex;
  width: 60%;
}
.project-site img {
  width: 100%;
}

.project-card {
  width: 40%;
  padding-left: 3rem;
}
.project-card h2 {
  margin-bottom: 0;
}

.card-link {
  display: block;
  font-size: 1.2rem;
  font-weight: 500;
  color: #999999;
  letter-spacing: 1px;
  margin: 0.3rem 0;
}

.description {
  font-style: italic;
  font-size: 1.4rem;
  font-weight: 700;
  color: #662E9B;
}

.card-tech-header {
  margin: 4rem 0 1rem;
  padding: 0.25rem 0 0.1rem 0.5rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 2px;
  color: #FFFFFF;
  background-color: #BC9D71;
}

.icons img {
  width: 50px;
  margin-right: 0.5rem;
  transition: all 0.5s ease-in-out;
}
.icons img:hover {
  transform: translateY(-0.5rem);
}
.icons .logo-adjust {
  width: 42px;
  margin-right: 1rem;
}

@media only screen and (max-width: 999px) {
  #featured-project-frame {
    display: block;
  }
  #featured-project-frame .project-site {
    width: 100%;
  }
  #featured-project-frame .project-card {
    width: 100%;
    margin-top: 3rem;
    padding-left: 0;
  }
  #featured-project-frame .project-card .description {
    margin: 1.5rem 0;
  }
}
#tech-stack {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
#tech-stack h1 {
  color: #1D265E;
  margin-bottom: 3rem;
}
#tech-stack #tech-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}
#tech-stack #tech-icons img {
  height: 60px;
  filter: grayscale(100%);
  margin: 2rem;
  transition: filter 0.5s ease-in-out, scale 0.5s ease-in-out;
}
#tech-stack #tech-icons img:hover {
  filter: none;
  scale: 1.3;
}

@media only screen and (max-width: 499px) {
  #tech-stack #tech-icons img {
    height: 4rem;
    margin: 2rem;
  }
}
.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.custom-btn {
  display: inline-flex;
  border: none;
  border-radius: 25px;
  padding: 10px 25px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu;
  font-size: 2rem;
  font-weight: 500;
  text-decoration: none;
}

.btn-body {
  padding: 0.7rem 1.5rem;
  font-size: 1.6rem;
}

.btn-light {
  background-color: #409FDD;
  color: #FFFFFF;
  align-items: center;
  transition: background-color 0.7s ease-in-out;
}
.btn-light:hover {
  background-color: #2386c7;
  color: #FFFFFF;
}

.btn-dark {
  background-color: #1D265E;
  color: #FFFFFF;
  align-items: center;
  transition: background-color 0.7s ease-in-out;
}
.btn-dark:hover {
  background-color: #111637;
  color: #FFFFFF;
}

.btn-bright {
  background-color: #662E9B;
  color: #FFFFFF;
  align-items: center;
  transition: background-color 1s ease-in-out, color 1s ease-in-out;
}
.btn-bright:hover {
  background-color: #4c2274;
  color: #FFFFFF;
}

.btn-sec-dark {
  background-color: #BC9D71;
  color: #FFFFFF;
  align-items: center;
  transition: 1s;
}
.btn-sec-dark:hover {
  background-color: #b49160;
  color: #FFFFFF;
  transition: 0.5s;
}

.inline-btn {
  margin-left: 15px;
}

.links a {
  color: #FF5E5B;
  font-size: 1.75rem;
  text-decoration: none;
  transition: 1.5s;
}
.links a:hover {
  color: #BC9D71;
  transition: 1s;
}

.resources a {
  color: #FF5E5B;
  text-decoration: none;
}
.resources a:hover {
  color: #BC9D71;
}

.info-card {
  border-radius: 25px;
  padding: 4rem;
}
.info-card .card-inner {
  display: grid;
  grid-template-columns: auto auto;
}
.info-card .card-image {
  justify-self: end;
}
.info-card .card-body {
  margin-top: 2rem;
  font-weight: 500;
  letter-spacing: 0.25px;
  line-height: 1.6;
  color: #1F1F1F;
}

@media only screen and (max-width: 499px) {
  .info-card {
    padding: 3rem;
  }
}
.card-descriptor {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 0;
  color: #FF5E5B;
}

footer {
  vertical-align: middle;
}
footer .footer-frame {
  margin: 0 0.5rem;
  padding-top: 7rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5rem;
  padding-bottom: 5rem;
}
footer .footer-frame p {
  font-weight: 700;
  color: #FFFFFF;
  letter-spacing: 1.5px;
}
footer .ftr-col-left .logo-text {
  font-size: 6rem;
  letter-spacing: 3px;
  color: #FFFFFF;
  display: block;
  line-height: 0.8;
}
footer .ftr-col-left .location {
  margin-top: 2.5rem;
  margin-bottom: 0;
}
footer .ftr-col-middle ul {
  display: block;
  padding-left: 0;
}
footer .ftr-col-middle ul li {
  list-style-type: none;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
footer .ftr-col-middle ul li span {
  color: #BC9D71;
  font-weight: 700;
  letter-spacing: 1px;
}
footer .ftr-col-middle ul li a {
  color: #FFFFFF;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 1px;
}
footer .ftr-col-middle ul li .nav-icon {
  margin-right: 2rem;
}
footer .ftr-col-middle ul li a {
  transition: color 0.5s ease-in-out;
}
footer .ftr-col-middle ul li a:hover {
  color: #409FDD;
}
footer .ftr-col-right ul {
  padding-left: 0;
}
footer .ftr-col-right ul li {
  display: inline;
  list-style-type: none;
}
footer .ftr-col-right ul li a svg {
  font-size: 4rem;
  color: #FFFFFF;
  transition: color ease-in-out 0.5s;
}
footer .ftr-col-right ul li a svg:hover {
  color: #409FDD;
}
footer .ftr-col-right ul li a:first-child {
  margin-right: 1.5rem;
}
footer .ftr-col-right p {
  margin-top: 2rem;
}
footer .ftr-col-right p span {
  margin-left: 2.2rem;
}
footer .sub-footer-container {
  display: grid;
  grid-template-columns: 98px 1fr 98px 1fr;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
footer .sub-footer-container .first-nation-flags,
footer .sub-footer-container .pride-flags {
  margin-top: 0.8rem;
}
footer .sub-footer-container .first-nation-flags img,
footer .sub-footer-container .pride-flags img {
  border: 2px solid #FAF4EF;
  outline: 1px solid #BC9D71;
}
footer .sub-footer-container .first-nation-flags img:nth-child(2) {
  margin-left: 1rem;
}
footer .sub-footer-container .pride-flags {
  text-align: right;
}
footer .sub-footer-container .first-nation-statement h4,
footer .sub-footer-container .pride-statement h4 {
  color: #765c38;
}
footer .sub-footer-container .first-nation-statement p,
footer .sub-footer-container .pride-statement p {
  color: #BC9D71;
  font-size: 1.1rem;
  font-style: italic;
  margin-bottom: 0;
}
@media only screen and (max-width: 1249px) {
  footer .footer-frame {
    grid-template-columns: 1.5fr 1fr 2fr;
  }
}
@media only screen and (max-width: 999px) {
  footer .footer-frame {
    grid-template-columns: 1fr 1fr;
  }
  footer .footer-frame .ftr-col-right {
    display: grid;
    grid-template-columns: max-content;
  }
  footer .sub-footer-container {
    grid-template-columns: 98px 1fr;
    row-gap: 3rem;
  }
}
@media only screen and (max-width: 599px) {
  footer .footer-frame {
    grid-template-columns: 1fr;
  }
}
footer #footer-gutter {
  height: 5rem;
}

#about-me {
  padding-top: 11rem;
  padding-bottom: 10rem;
}
#about-me h4 {
  margin-bottom: 2rem;
  color: #662E9B;
}
#about-me p {
  font-size: 2rem;
}
#about-me a {
  font-size: 2rem;
}

.about-grid {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  -moz-column-gap: 3rem;
       column-gap: 3rem;
}
.about-grid .about-img-body {
  display: none;
}
.about-grid .about-text {
  padding-right: 4rem;
}
.about-grid .about-img img {
  width: 100%;
  opacity: 0.8;
}

@media only screen and (max-width: 999px) {
  #about-me h4 {
    margin-bottom: 0;
  }
  .about-grid {
    margin-top: 2rem;
    grid-template-columns: 1fr;
  }
  .about-grid .about-text {
    padding-right: 0;
  }
  .about-grid .about-img-aside {
    display: none;
  }
  .about-grid .about-img-body {
    display: contents;
  }
  .about-grid .about-img-body img {
    height: 20rem;
    -o-object-fit: cover;
       object-fit: cover;
    margin: 2rem 0;
  }
}
#not-found {
  padding-top: 11rem;
  padding-bottom: 10rem;
}
#not-found .not-found-grid {
  display: grid;
  grid-template-columns: 1.25fr 1fr;
}
#not-found .not-found-grid .not-found-img-aside {
  justify-self: center;
}
#not-found .not-found-grid .not-found-img-aside img {
  width: 80%;
}
#not-found .not-found-grid .not-found-img-body {
  display: none;
}
#not-found p {
  font-size: 2rem;
}
#not-found p:first-of-type {
  margin-top: 5rem;
}
#not-found .get-back {
  margin-top: 3rem;
}

@media only screen and (max-width: 999px) {
  #not-found .not-found-grid {
    grid-template-columns: 1fr;
  }
  #not-found .not-found-img-aside {
    display: none;
  }
  #not-found .not-found-grid .not-found-img-body {
    display: contents;
  }
  #not-found .not-found-grid .not-found-img-body img {
    width: 25rem;
    margin-top: 3rem;
  }
}
#contact-me {
  padding-top: 11rem;
  padding-bottom: 10rem;
}
#contact-me p {
  font-size: 2rem;
}
#contact-me p:first-of-type {
  margin-top: 5rem;
}
#contact-me .email {
  margin-top: 5rem;
}
#contact-me .email .email-logo {
  background-color: #1D265E;
  color: #FFFFFF;
  padding: 1rem 1.3rem 1.3rem;
  border-radius: 5rem;
}
#contact-me .email .email-address {
  margin-left: 3rem;
}
#contact-me .email .email-address a {
  font-size: 2rem;
}

@media only screen and (max-width: 999px) {
  .remove {
    display: none;
  }
}
#project-body {
  padding-top: 11rem;
  padding-bottom: 10rem;
}
#project-body #projects-group .project-column {
  margin-top: 15rem;
  display: grid;
  grid-template-columns: 1fr 2.5fr 2fr;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
  background-color: #FFFFFF;
  padding: 6rem 3rem;
  border-radius: 5rem;
}
#project-body #projects-group .project-column:first-child {
  margin-top: 5rem;
}
#project-body #projects-group .project-column .project-main-img img {
  width: 100%;
}
#project-body #projects-group .project-column .project-text {
  padding-left: 2rem;
}
#project-body #projects-group .project-column .custom-btn {
  margin-top: 4rem;
  margin-right: 1.5rem;
}
@media only screen and (max-width: 999px) {
  #project-body #projects-group .project-column {
    grid-template-columns: 100%;
  }
  #project-body #projects-group .project-column .project-main-img img {
    margin: 1rem 0 4rem;
  }
  #project-body #projects-group .project-column .project-text {
    padding-left: 0;
  }
}

#site-frame {
  height: 100%;
  width: 100%;
  position: sticky;
  overflow: scroll;
  border: 15px solid #FFFFFF;
}

@media only screen and (max-width: 499px) {
  #site-frame {
    border: none;
  }
}
#site-logo {
  font-family: "ShadowsIntoLight", cursive;
}

.logo-highlight {
  font-family: "ShadowsIntoLight", cursive;
}

.highlight-light {
  color: #409FDD;
}

.highlight-bright {
  color: #FFFFFF;
}

.highlight-dark {
  color: #1D265E;
}